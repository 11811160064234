<template>
  <div>
    <div
      class="absolute -mt-8 text-left text-sm cursor-pointer flex text-coolGray-800 italic mb-1"
      v-on:click="afficherFormulaireLogin"
    >
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 477.175 477.175"
        style="enable-background: new 0 0 477.175 477.175"
        xml:space="preserve"
        class="mr-1"
      >
        <g>
          <path
            d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
    c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
          />
        </g>
      </svg>
      {{ $t("retour") }}
    </div>
    <div class="font-bold text-2xl text-left">
      {{ $t("mot_de_passe.reinit.titre") }}
    </div>
    <div class="font-light text-left">
      {{ $t("mot_de_passe.reinit.texte") }}
    </div>
    <form class="mt-5">
      <div class="flex mb-3">
        <div class="h-12 icon-username"></div>
        <div class="h-12 flex-auto">
          <input
            v-model="login"
            id="keops-login"
            autocomplete="username"
            :placeholder="$t('login')"
            class="appearance-none block w-full px-4 outline-none focus:outline-none"
          />
        </div>
      </div>

      <div class="flex">
        <div class="h-12 icon-email"></div>
        <div class="h-12 flex-auto">
          <input
            v-model="email"
            type="email"
            id="keops-email"
            autocomplete="email"
            :placeholder="$t('contact.email')"
            class="appearance-none block w-full px-4 outline-none focus:outline-none"
          />
        </div>
      </div>

      <div class="text-center">
        <button
          class="bg-black mt-5 text-white py-2 px-8 text-lg font-light w-10/12"
          @click="resetPassword($event)" :disabled="enCours"
        >
          {{ $t("contact.envoyer") }}
        </button>

        <Message
          v-if="message.description !== ''"
          titre=""
          :description="message.description"
          :type="message.type"
          :couleur="message.couleur"
          class="mt-2"
        />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Message from "./MessageErreur";

export default {
  components: { Message },
  data: function () {
    return {
      login: "",
      email: "",
      message: {
        type: "",
        couleur: "",
        titre: "",
        description: "",
      },
      enCours : false
    };
  },
  computed: {},
  mounted: function () {},
  methods: {
    afficherFormulaireLogin: function () {
      this.$parent.motPasseOublie = false;
    },
    resetPassword: async function () {
      if (event) {
        event.preventDefault();
      }
      if (this.email != "" && this.login != "") {
        this.enCours = true;
        this.message.type = "chargement";
        this.message.couleur = "gray";
        this.message.description = this.$i18n.t("contact.envoi.en_cours.titre");
        
        try {
          await axios.post(
            process.env.VUE_APP_API + "/user/resetPassword.php",
            {
              login: this.login,
              email: this.email,
            }
          );
          this.message.couleur = "blue";
          this.message.type = "email";
          this.message.description = this.$i18n.t("mot_de_passe.mail-envoi");
          this.enCours = false;
        } catch (error) {
          this.message.couleur = "red";
          this.message.type = "erreur";
          this.message.description = this.$i18n.t("mot_de_passe.reinit.erreur");
          this.enCours = false;
        }
      } else {
        this.message.couleur = "blue";
        this.message.type = "info";
        this.message.description = this.$i18n.t("messages.champ_vide.titre");
      }
    },
  },
};
</script>

<style scoped lang="scss">
form input:focus {
  transition: all 0.2s ease-in-out 0s;
  box-shadow: inset 0 0 0 1px #63b3ed;
}

form button {
  outline: none;
  box-shadow: none;
}
</style>
