<template>
  <!--Header Alert-->
  <div class="">
    <input type="checkbox" class="hidden" id="banneralert" />
    <label
      class="alert-banner w-full fixed top-0 z-20 close cursor-pointer flex items-center justify-between w-full p-2 shadow text-white"
      title="close"
      for="banneralert"
      :class="{ 'bg-blue-500': !isAssor }"
      :style="{ background: colorAssor }"
    >
      <div class="mr-2 ml-2 md:ml-5 md:mr-5">
        {{ this.$i18n.t("banniere.texte") }}
        <div class="mt-2">
          <label class="checkbox-container fill-current mr-5 text-sm">
            {{ this.$i18n.t("banniere.plus-afficher") }}
            <input
              id="cacherBanniere"
              type="checkbox"
              v-model="cacherBanniere"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="flex items-start">
        <svg
          version="1.1"
          id="close"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          xml:space="preserve"
          class="fill-current text-white"
        >
          <g>
            <g>
              <path
                d="M437.019,74.981C388.668,26.628,324.378,0,256,0C187.62,0,123.333,26.628,74.981,74.98S0,187.62,0,256
        c0,68.381,26.628,132.668,74.981,181.02C123.332,485.372,187.62,512,255.999,512s132.669-26.628,181.02-74.98
        C485.371,388.667,512,324.38,512,256C512,187.619,485.371,123.332,437.019,74.981z M368.853,347.642
        c5.857,5.857,5.857,15.355,0,21.213c-2.93,2.929-6.768,4.393-10.607,4.393c-3.838,0-7.678-1.465-10.605-4.393L256,277.214
        l-91.641,91.641c-2.93,2.929-6.768,4.393-10.607,4.393c-3.838,0-7.678-1.465-10.605-4.393c-5.858-5.857-5.858-15.355,0-21.213
        l91.64-91.641l-91.64-91.641c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.857,15.354-5.857,21.213,0L256,234.788l91.64-91.641
        c5.857-5.857,15.355-5.857,21.213,0c5.857,5.857,5.857,15.355,0,21.213l-91.641,91.641L368.853,347.642z"
              />
            </g>
          </g>
        </svg>

        <!-- <svg
          class="fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg> -->
      </div>
    </label>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      cacherBanniere: false,
    };
  },
  computed: {
    isAssor: function () {
      return this.$parent.isAssor;
    },
    colorAssor: function () {
      if (this.isAssor) {
        return "#294e6c";
      }
      return "";
    },
  },
  mounted() {
    if (localStorage.cacherBanniere) {
      this.cacherBanniere = localStorage.cacherBanniere == "true";
      document.getElementById("banneralert").checked = this.cacherBanniere;
    }
  },
  watch: {
    cacherBanniere(val) {
      this.cacherBanniere = val;
      localStorage.cacherBanniere = this.cacherBanniere;
    },
  },
};
</script>

<style scoped>
/* The checkbox-container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  padding-top: 2px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 13px;
  width: 13px;
  border: 1px solid #2b6cb0;
  background-color: #fff5f5;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  /* background-color: #eee; */
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  /* background-color: #2196f3; */
  background-color: #2b6cb0;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Banner open/load animation*/
.alert-banner {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Banner close animation*/
#banneralert:checked ~ * {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
</style>
