<template>
  <div class="font-sans">
    <Banniere />
    <div
      class="col-span-3 h-full w-full overlay"
      v-on:click="cacherFormulaireContact"
      :class="{ 'overlay-visible': isContact }"
    >
      <Contact :key="contactIndex" />
    </div>
    <!-- <Login class="block sm:hidden" /> -->
    <div class="max-w-screen-xl md:h-screen m-auto">
      <div class="flex items-center justify-center h-full">
        <div
          style="height: 750px"
          class="w-full flex lg:shadow-2xl lg:rounded overflow-auto"
        >
          <div
            class="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 relative h-full mx-auto bg-white"
          >
            <div class="p-6">
              <div class="mb-5 md:mb-8">
                <div v-if="isAssor" class="mb-12 mt-5">
                  <img
                    src="./assets/img/assor/logo.png"
                    class="md:w-10/12 w-3/5 max-w-xs mx-auto mb-7 font-cairo"
                  />
                </div>
                <div v-else class="font-cairo">
                  <img
                    src="./assets/img/logo-keops.svg"
                    class="md:w-6/12 w-2/5 max-w-xs mx-auto mb-1"
                  />
                  <div class="text-5xl -mt-4 text-center">KEOPS</div>
                </div>
              </div>
              <Login class="mx-auto h-full" />
              <div
                class="absolute left-0 right-0 text-center text-xs bottom-0 text-blue-smaio-dark"
              >
                <div v-if="isAssor" style="background: #294e6c" class="pt-4">
                  <img
                    src="./assets/img/assor/ssa-logo.png"
                    class="mx-auto w-2/12"
                  />
                  <img
                    src="./assets/img/assor/monash-logo.png"
                    class="mx-auto w-2/5"
                  />
                </div>
                <div v-else class="pb-5">
                  <a href="http://www.smaio.com" target="_blank">
                    <img
                      src="./assets/logoSmaio.png"
                      class="h-20 object-scale-down mx-auto inline"
                    />
                    <p>www.smaio.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            class="lg:w-8/12 relative overflow-hidden h-full hidden lg:block"
          >
            <div v-if="isAssor" class="feat w-full h-full relative flex">
              <img
                src="./assets/img/assor/fond.jpg"
                class="w-full h-full object-cover"
              />
            </div>
            <div v-else>
              <div class="pagination absolute z-10 right-0 pr-2">
                <a
                  v-for="n in slide.nb"
                  :key="n"
                  class="dot"
                  :class="{ active: slide.courant == n }"
                  @click="changerSlide(n)"
                ></a>
              </div>

              <div
                id="feat1"
                class="feat w-full h-full relative flex"
                :class="{ fade: slide.courant != 1 }"
                style="background-color: #ececec"
              >
                <img
                  v-if="this.$i18n.locale.includes('fr')"
                  src="./assets/img/features/01.png"
                  class="w-11/12 object-contain mx-auto"
                />
                <img
                  v-else
                  src="./assets/img/features/01-en.png"
                  class="w-11/12 object-contain mx-auto"
                />
              </div>

              <Feat
                v-for="(feat, index) in features"
                :key="index"
                :data="feat"
                :index="index + 2"
                :class="{ fade: slide.courant != index + 2 }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Feat from "./components/Feat";
import Login from "./components/Login";
import Banniere from "./components/Banniere";
import Contact from "./components/Contact";

export default {
  name: "App",
  components: { Feat, Login, Banniere, Contact },
  data: function () {
    return {
      isAssor: false,
      isContact: false,
      contactIndex: 0,
      slide: {
        courant: 1,
        precedent: 1,
        nb: 4,
        timer: null,
        delay: process.env.VUE_APP_DELAI,
      },
      features: [
        {
          titre: this.$i18n.t("dossier_patient.titre"),
          description: this.$i18n.t("dossier_patient.description"),
        },
        {
          titre: this.$i18n.t("formulaire_patient.titre"),
          description: this.$i18n.t("formulaire_patient.description"),
        },
        {
          titre: this.$i18n.t("suivi_patient.titre"),
          description: this.$i18n.t("suivi_patient.description"),
        },
      ],
    };
  },
  mounted: function () {
    this.isAssor = window.location.host.includes("assor");

    if (!this.isAssor) {
      this.setTimer();
    }
    let self = this;
    window.addEventListener("keyup", function (ev) {
      if (ev.keyCode == 27) {
        self.cacherFormulaireContact();
      }
    });
  },
  methods: {
    setTimer: function () {
      const v = this;
      this.slide.timer = setInterval(function request() {
        v.slide.precedent = v.slide.courant;
        v.slide.courant++;

        if (v.slide.courant > v.slide.nb) {
          v.slide.courant = 1;
        }
      }, v.slide.delay);
    },
    changerSlide: function (feat) {
      if (feat !== this.slide.courant) {
        clearInterval(this.slide.timer);

        this.slide.precedent = this.slide.courant;
        this.slide.courant = feat;
        this.setTimer();
      }
    },
    cacherFormulaireContact: function () {
      if (this.isContact) {
        this.isContact = false;
        this.contactIndex++;
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @apply fond_softWhite;

  // @media (min-width: 640px) {
  //   background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  // }

  // @media (min-width: 1024px) {
  //   @apply .fond_softWhite;
  // }

  // @media (min-width: 1280px) {
  //   @apply .fond_softWhite;
  // }
}

.fond_softWhite {
  background-image: linear-gradient(
    to top,
    #d5d4d0 0%,
    #d5d4d0 1%,
    #eeeeec 31%,
    #efeeec 75%,
    #e9e9e7 100%
  );
}
$bezier: cubic-bezier(0.25, 0.8, 0.25, 1);

.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .dot {
    background: rgba(black, 0.25);
    border-radius: 0.4rem;
    height: 0.8rem;
    margin: 0 0.4rem;
    transform: scale(0.75);
    transition: transform 1s $bezier, background 0.4s $bezier;
    width: 0.8rem;
    cursor: pointer;

    &.active {
      background: #05040a;
      transform: scale(1.1);
    }
  }
}

div.feat.fade {
  opacity: 0;
  transform: scale(1.1);
  // transition: all 1.5s $bezier;
}
div.feat {
  opacity: 1;
  transform: scale(1);
  position: absolute;
  transition: all 1.5s $bezier;
}

.overlay {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @apply fixed z-20;
  //transform: translateY(-100%);
  top: -100%;
  transition: opacity 0.5s $bezier;
}

.overlay-visible {
  transform: translateY(-50%);
  top: 50%;
  opacity: 1;
  scale: 1;
}
</style>
