<template>
  <div
    class="rounded-b text-gray-700 px-4 py-3 border-t-4 text-left"
    :class="classCouleur1"
  >
    <div class="flex">
      <div class="py-1" :class="classCouleur2">
        <div v-if="type == 'info'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59">
            <title>info</title>
            <g>
              <g>
                <g>
                  <path
                    d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0v-14a2.5,2.5,0,0,1,5,0Zm0-22.83a2.5,2.5,0,0,1-5,0V17.5a2.5,2.5,0,0,1,5,0Z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div v-if="type == 'erreur'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.6 51.59">
            <title>warning</title>
            <g>
              <g>
                <g>
                  <path
                    d="M50.7,39.28,33.41,4.7a8.5,8.5,0,0,0-15.21,0L.91,39.28a8.51,8.51,0,0,0,7.6,12.31H43.1a8.51,8.51,0,0,0,7.6-12.31ZM28.31,39a2.5,2.5,0,0,1-5,0V38a2.5,2.5,0,0,1,5,0Zm0-8.5a2.5,2.5,0,0,1-5,0v-12a2.5,2.5,0,0,1,5,0Z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div v-if="type == 'bloque'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59">
            <title>lock</title>
            <g>
              <g>
                <path
                  d="M38.5,19.5h-1V11a11,11,0,0,0-11-11h-8a11,11,0,0,0-11,11v8.5h-1A6.51,6.51,0,0,0,0,26V49A10.51,10.51,0,0,0,10.5,59.5h24A10.51,10.51,0,0,0,45,49V26A6.51,6.51,0,0,0,38.5,19.5ZM25,39.45V45a2.5,2.5,0,0,1-5,0V39.45a2.5,2.5,0,0,1,.5-5h4a2.5,2.5,0,0,1,.5,5ZM31.5,19h-18V11a5,5,0,0,1,5-5h8a5,5,0,0,1,5,5Z"
                />
              </g>
            </g>
          </svg>
        </div>

        <div v-if="type == 'chargement'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            class="h-8 w-8"
          >
            <path d="M10 50A40 40 0 0 0 90 50A40 50 0 0 1 10 50" stroke="none">
              <animateTransform
                attributeName="transform"
                type="rotate"
                dur="0.9090909090909091s"
                repeatCount="indefinite"
                keyTimes="0;1"
                values="0 50 55;360 50 55"
              />
            </path>
          </svg>
        </div>

        <div v-if="type == 'email'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 51">
            <path
              d="M48.5,0h-38A10.51,10.51,0,0,0,0,10.5v30A10.51,10.51,0,0,0,10.5,51h38A10.51,10.51,0,0,0,59,40.5v-30A10.51,10.51,0,0,0,48.5,0Zm3.23,17.31L33.79,27.4a8.85,8.85,0,0,1-8.58,0L7.27,17.31A2.5,2.5,0,1,1,9.73,13L27.66,23a3.77,3.77,0,0,0,3.68,0L49.27,13a2.5,2.5,0,1,1,2.46,4.35Z"
            />
          </svg>
        </div>
      </div>
      <div>
        <p class="font-bold">{{ titre }}</p>
        <p class="text-sm" v-html="description">
          <!-- <span v-html="tentatives"></span>-->
        </p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  props: {
    couleur: String,
    titre: String,
    description: String,
    type: String,
  },
  computed: {
    classCouleur1: function () {
      if (this.couleur == "blue") {
        return "bg-blue-100 border-blue-500";
      }
      if (this.couleur == "red") {
        return "bg-red-100 border-red-500";
      }
      if (this.couleur == "orange") {
        return "bg-orange-100 border-orange-500";
      }
      if (this.couleur == "green") {
        return "bg-green-100 border-green-500";
      }
      if (this.couleur == "gray") {
        return "bg-gray-100 border-gray-500";
      }
      return "";
    },
    classCouleur2: function () {
      if (this.couleur == "blue") {
        return "text-blue-500";
      }
      if (this.couleur == "red") {
        return "text-red-500";
      }
      if (this.couleur == "orange") {
        return "text-orange-500";
      }
      if (this.couleur == "green") {
        return "text-green-500";
      }
      if (this.couleur == "gray") {
        return "text-gray-500";
      }
      return "";
    },
  },
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss">
svg {
  @apply fill-current h-6 w-6 mr-4;
}
</style>