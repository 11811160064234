<template>
  <div :id="'feat'+index" class="feat w-full h-full relative">
    <img :src="urlImage" class="w-full h-full object-cover" />

    <div class="absolute bottom-0 mb-5 inset-x-0 shadow-xl bg-white w-4/5 mx-auto p-5">
      <div class="text-xl font-bold">{{data.titre}}</div>
      <div class="font-thin">
        <p v-for="(desc, index) in data.description" :key="index">{{desc}}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  props: {
    data: Object,
    index: Number,
    titre: String,
    description: Array,
  },
  computed: {
    urlImage() {
      return require("../assets/img/features/0" + this.index + ".jpg");
    },
  },
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss">
</style>