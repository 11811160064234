<template>
  <div class="flex h-full overflow-auto">
    <div
      class="p-5 md:px-8 m-auto w-full md:w-auto bg-white relative"
      style="width: 600px"
      v-on:click.stop
    >
      <div class="font-bold uppercase text-2xl md:text-3xl mb-5 pt-0 flex">
        <div class="my-auto">
          <svg
            viewBox="0 0 448 448"
            xmlns="http://www.w3.org/2000/svg"
            class="w-10 h-10 md:w-12 md:h-12"
          >
            <path d="m314.375 144h-180.75l90.375 77.464844zm0 0" />
            <path
              d="m224 240c-1.910156 0-3.757812-.683594-5.207031-1.929688l-98.792969-84.679687v150.609375h208v-150.609375l-98.792969 84.679687c-1.449219 1.246094-3.296875 1.929688-5.207031 1.929688zm0 0"
            />
            <path
              d="m224 0c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm120 312c0 4.417969-3.582031 8-8 8h-224c-4.417969 0-8-3.582031-8-8v-176c0-4.417969 3.582031-8 8-8h224c4.417969 0 8 3.582031 8 8zm0 0"
            />
          </svg>
        </div>

        {{ this.$i18n.t("contact.titre2") }}
      </div>

      <div
        class="absolute right-0 top-0 cursor-pointer"
        v-on:click="afficherFormulaireLogin"
      >
        <svg
          version="1.1"
          id="close"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          xml:space="preserve"
          class="h-5 w-5 m-5 text-coolGray-400"
        >
          <g>
            <g>
              <path
                d="M437.019,74.981C388.668,26.628,324.378,0,256,0C187.62,0,123.333,26.628,74.981,74.98S0,187.62,0,256
          c0,68.381,26.628,132.668,74.981,181.02C123.332,485.372,187.62,512,255.999,512s132.669-26.628,181.02-74.98
          C485.371,388.667,512,324.38,512,256C512,187.619,485.371,123.332,437.019,74.981z M368.853,347.642
          c5.857,5.857,5.857,15.355,0,21.213c-2.93,2.929-6.768,4.393-10.607,4.393c-3.838,0-7.678-1.465-10.605-4.393L256,277.214
          l-91.641,91.641c-2.93,2.929-6.768,4.393-10.607,4.393c-3.838,0-7.678-1.465-10.605-4.393c-5.858-5.857-5.858-15.355,0-21.213
          l91.64-91.641l-91.64-91.641c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.857,15.354-5.857,21.213,0L256,234.788l91.64-91.641
          c5.857-5.857,15.355-5.857,21.213,0c5.857,5.857,5.857,15.355,0,21.213l-91.641,91.641L368.853,347.642z"
              />
            </g>
          </g>
        </svg>
      </div>

      <form class="z-10 relative">
        <div class="md:flex">
          <div
            class="md:mr-1 md:w-1/2"
            :class="{
              erreurChamp: erreur.includes('nom'),
              champ: !erreur.includes('nom'),
            }"
          >
            <label class="text-left block uppercase tracking-wide text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 59 59"
                v-if="erreur.includes('nom')"
              >
                <title>error</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g id="error">
                      <path
                        d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0V40.33a2.5,2.5,0,0,1,5,0Zm0-9.95a2.5,2.5,0,1,1-5,0v-14a2.5,2.5,0,0,1,5,0Z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              {{ $t("contact.nom") }}
            </label>
            <input
              v-model="nom"
              autocomplete="family-name"
              class="appearance-none block w-full py-3 px-4 mb-3 outline-none border-solid border"
            />
          </div>

          <div
            class="md:ml-1 md:w-1/2"
            :class="{
              erreurChamp: erreur.includes('prenom'),
              champ: !erreur.includes('prenom'),
            }"
          >
            <label class="text-left block uppercase tracking-wide text-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 59 59"
                v-if="erreur.includes('prenom')"
              >
                <title>error</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g id="error">
                      <path
                        d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0V40.33a2.5,2.5,0,0,1,5,0Zm0-9.95a2.5,2.5,0,1,1-5,0v-14a2.5,2.5,0,0,1,5,0Z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              {{ $t("contact.prenom") }}
            </label>
            <input
              v-model="prenom"
              autocomplete="given-name"
              class="appearance-none block w-full py-3 px-4 mb-3 outline-none border-solid border W-FULL"
            />
          </div>
        </div>

        <div
          class="mb-3"
          :class="{
            erreurChamp: erreur.includes('email'),
            champ: !erreur.includes('email'),
          }"
        >
          <label
            class="text-left block uppercase tracking-wide text-grey-darker text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 59 59"
              v-if="erreur.includes('email')"
            >
              <title>error</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="error">
                    <path
                      d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0V40.33a2.5,2.5,0,0,1,5,0Zm0-9.95a2.5,2.5,0,1,1-5,0v-14a2.5,2.5,0,0,1,5,0Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            {{ $t("contact.email") }}
          </label>
          <input
            v-model="email"
            autocomplete="email"
            class="appearance-none block w-full py-3 px-4 mb-3 outline-none border-solid border border-gray-300 focus:border-blue-400"
          />
        </div>

        <div
          class="mb-3"
          :class="{
            erreurChamp: erreur.includes('email2'),
            champ: !erreur.includes('email2'),
          }"
        >
          <label
            class="text-left block uppercase tracking-wide text-grey-darker text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 59 59"
              v-if="erreur.includes('email2')"
            >
              <title>error</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="error">
                    <path
                      d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0V40.33a2.5,2.5,0,0,1,5,0Zm0-9.95a2.5,2.5,0,1,1-5,0v-14a2.5,2.5,0,0,1,5,0Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            {{ $t("contact.emailConfirm") }}
          </label>
          <input
            v-model="email2"
            autocomplete="email"
            class="appearance-none block w-full py-3 px-4 mb-3 outline-none border-solid border border-gray-300 focus:border-blue-400"
          />
        </div>

        <div
          class="mb-3"
          :class="{
            erreurChamp: erreur.includes('message'),
            champ: !erreur.includes('message'),
          }"
        >
          <label
            class="text-left block uppercase tracking-wide text-grey-darker text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 59 59"
              v-if="erreur.includes('message')"
            >
              <title>error</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="error">
                    <path
                      d="M29.5,0A29.5,29.5,0,1,0,59,29.5,29.54,29.54,0,0,0,29.5,0ZM32,41.5a2.5,2.5,0,0,1-5,0V40.33a2.5,2.5,0,0,1,5,0Zm0-9.95a2.5,2.5,0,1,1-5,0v-14a2.5,2.5,0,0,1,5,0Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            {{ $t("contact.message") }}
          </label>
          <textarea
            v-model="message"
            autocomplete="off"
            rows="7"
            class="appearance-none block w-full py-3 px-4 mb-3 outline-none border-solid border border-gray-300 focus:border-blue-400"
          />
        </div>

        <div class="text-center">
          <button
            class="bg-black mt-5 mb-2 text-white py-2 px-10 inline text-lg font-light rounded-sm"
            @click="envoieEmail($event)"
          >
            {{ $t("contact.envoyer") }}
          </button>
        </div>

        <div :class="{ invisible: !isMessage }" class="h-24">
          <Message
            :titre="messageEnvoi.titre"
            :description="messageEnvoi.description"
            :type="messageEnvoi.type"
            :couleur="messageEnvoi.couleur"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Message from "./MessageErreur";

export default {
  components: { Message },
  data: function () {
    return {
      email: "",
      email2: "",
      nom: "",
      prenom: "",
      message: "",
      erreur: [],
      contact: process.env.VUE_APP_KEOPS_CONTACT_EMAIL,
      isMessage: false,
      messageEnvoi: {
        type: "",
        couleur: "",
        titre: "",
        description: "",
      },
    };
  },
  computed: {},
  mounted: function () {},
  methods: {
    afficherFormulaireLogin: function () {
      this.$parent.isContact = false;
      this.$parent.contactIndex++;
    },
    envoieEmail: async function (event) {
      if (event) {
        event.preventDefault();
      }

      this.erreur = [];
      this.isMessage = false;
      if (this.nom == "") {
        this.erreur.push("nom");
      }
      if (this.prenom == "") {
        this.erreur.push("prenom");
      }
      if (this.email == "") {
        this.erreur.push("email");
      }
      if (this.email2 == "") {
        this.erreur.push("email2");
      }
      if (this.email != this.email2) {
        this.erreur.push("email2");
      }
      if (this.message == "") {
        this.erreur.push("message");
      }

      if (this.erreur.length == 0) {
        this.isMessage = true;

        this.messageEnvoi.type = "chargement";
        this.messageEnvoi.couleur = "gray";
        this.messageEnvoi.titre = this.$i18n.t("contact.envoi.en_cours.titre");
        this.messageEnvoi.description = this.$i18n.t(
          "contact.envoi.en_cours.description"
        );

        axios
          .post(process.env.VUE_APP_API + `/user/contact.php`, {
            nom: this.nom,
            prenom: this.prenom,
            email: this.email,
            message: this.message,
          })
          .then(() => {
            this.messageEnvoi.type = "email";
            this.messageEnvoi.couleur = "blue";
            this.messageEnvoi.titre = this.$i18n.t(
              "contact.envoi.succes.titre"
            );
            this.messageEnvoi.description = this.$i18n.t(
              "contact.envoi.succes.description"
            );
          })
          .catch(() => {
            this.isMessage = true;

            this.messageEnvoi.type = "erreur";
            this.messageEnvoi.couleur = "red";
            this.messageEnvoi.titre = this.$i18n.t(
              "contact.envoi.erreur.titre"
            );
            this.messageEnvoi.description = this.$i18n.t(
              "contact.envoi.erreur.description",
              { contact: process.env.VUE_APP_KEOPS_CONTACT_EMAIL }
            );
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
form input {
  @apply bg-coolGray-100 h-12;
}
form textarea {
  @apply bg-coolGray-100;
}

form input:focus,
form textarea:focus {
  transition: all 0.2s ease-in-out 0s;
  box-shadow: inset 0 0 0 1px #63b3ed;
}

form button {
  outline: none;
  box-shadow: none;
}

.erreurChamp svg {
  @apply fill-current h-4 w-4 mr-2;
}
.erreurChamp label {
  @apply flex text-red-600 m-1;
}
.erreurChamp input,
.erreurChamp textarea {
  @apply border-red-300;
}
.erreurChamp input:focus,
.erreurChamp textarea:focus {
  outline: none;
}

.champ label {
  @apply text-black m-1;
}
.champ input {
  @apply border-gray-300;
}
.champ input:focus {
  @apply border-blue-400;
}

textarea {
  white-space: pre-wrap;
}
</style>
