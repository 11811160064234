import Vue from "vue";
import App from "./App.vue";

import "@/assets/styles/tailwind.css";
import i18n from "./i18n";

// import { VueReCaptcha } from "vue-recaptcha-v3";
// Vue.use(VueReCaptcha, {
//   siteKey: process.env.VUE_APP_RECAPTCHA,
//   loaderOptions: {
//     useRecaptchaNet: true,
//     autoHideBadge: true,
//   },
// });
Vue.config.productionTip = false;
new Vue({
  i18n,
  render: (h) => h(App),
}).$mount("#app");
