<template>
  <div>
    <Password v-if="motPasseOublie" />
    <div v-else>
      <form id="myForm" :action="keopsUrl + '/index_login.php'" method="post">
        <input type="hidden" name="token" id="token" />
      </form>
      <form>
        <div class="flex mb-3">
          <div class="h-12 icon-username"></div>
          <div class="h-12 flex-auto">
            <input
              v-model="login"
              id="keops-login"
              autocomplete="username"
              :placeholder="$t('login')"
              class="appearance-none block w-full px-4 outline-none focus:outline-none"
            />
          </div>
        </div>

        <div class="flex">
          <div class="h-12 icon-password"></div>
          <div class="h-12 flex-auto">
            <input
              v-model="password"
              type="password"
              id="keops-password"
              autocomplete="password"
              :placeholder="$t('mot_de_passe.titre')"
              class="appearance-none block w-full px-4 outline-none focus:outline-none"
            />
          </div>
        </div>
        <i
          class="block text-left text-sm cursor-pointer mb-3"
          v-on:click="afficherFormulaireMotPasse"
          >{{ $t("mot_de_passe.oubli") }}</i
        >
        <div class="text-center">
          <button
            class="bg-black mt-5 text-white py-2 px-4 text-lg font-light w-10/12 rounded-sm"
            @click="connection($event)"
          >
            {{ $t("connexion") }}
          </button>
          <div v-if="isAssor" class="text-sm mt-3 text-left w-10/12 mx-auto">
            <div class="font-bold">Contact Us</div>

            <div>Ph: 1800 998 722</div>
            <div>Email: spineregistryau@monash.edu</div>
          </div>
          <div v-else class="text-sm mt-3">
            {{ this.$i18n.t("contact.questions") }}
            <b class="cursor-pointer" v-on:click="afficherFormulaireContact">{{
              this.$i18n.t("contact.titre2")
            }}</b>
          </div>
        </div>

        <div
          id="connexionMessage"
          class="h-24 mt-3"
          :class="{ invisible: isMessage }"
        >
          <Message
            :titre="message.titre"
            :description="message.description"
            :type="message.type"
            :couleur="message.couleur"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Message from "./MessageErreur";
import Password from "./Password";

export default {
  components: { Message, Password },
  data: function () {
    return {
      motPasseOublie: false,
      login: "",
      password: "",
      tentatives: 0,
      message: {
        type: "",
        couleur: "",
        titre: "",
        description: "",
      },
    };
  },
  computed: {
    isMessage: function () {
      return this.message.type == "";
    },
    keopsUrl: function () {
      return process.env.VUE_APP_KEOPS_URL;
    },
    isAssor: function () {
      return this.$parent.isAssor;
    },
  },
  mounted: function () {},
  methods: {
    afficherFormulaireMotPasse: function () {
      this.motPasseOublie = true;
    },
    afficherFormulaireContact: function () {
      this.$parent.isContact = true;
    },
    connection: async function (event) {
      if (event) {
        event.preventDefault();
      }

      if (
        this.login != "" &&
        this.password != "" &&
        this.password.length >= 8
      ) {
        const formData = new FormData();
        formData.append("login", this.login);
        formData.append("password", this.password);
        // formData.append("login", "g.floret2");
        // formData.append("password", "Floretinho69!8");

        let myToken = "";
        try {
          const retour = await axios.post(
            process.env.VUE_APP_API + "/user/login.php",
            {
              login: this.login,
              password: this.password,
            }
          );
          console.log(retour);

          myToken = retour.data.token;

          this.message.type = "chargement";
          this.message.couleur = "gray";
          this.message.titre = this.$i18n.t(
            "messages.connexion_en_cours.titre"
          );
          this.message.description = this.$i18n.t(
            "messages.connexion_en_cours.description"
          );

          setTimeout(() => {
            document
              .getElementById("myForm")
              .getElementsByTagName("input")[0].value = myToken;
            document.getElementById("myForm").submit();
            this.message.type = "";
          }, 500);
        } catch (error) {
          console.log(error.response);
          //Compte / IP bloqués
          if (error.response.data.erreur == -2) {
            this.message.type = "bloque";
            this.message.couleur = "red";
            this.message.titre = this.$i18n.t("messages.compte_bloque.titre");
            this.message.description = this.$i18n.t(
              "messages.compte_bloque.description",
              { contact: process.env.VUE_APP_KEOPS_CONTACT_EMAIL }
            );
          }
          if (error.response.data.erreur == -1) {
            this.message.type = "erreur";
            this.message.couleur = "orange";

            if (error.response.data.tentatives !== undefined) {
              this.message.titre = this.$i18n.t(
                "messages.password_erreur.titre"
              );
              this.message.description = this.$i18n.tc(
                "messages.password_erreur.description",
                error.response.data.tentatives
              );
            }
            if (
              error.response.data.compte_existant !== undefined &&
              !error.response.data.compte_existant
            ) {
              this.message.titre = this.$i18n.t(
                "messages.compte_inexistant.titre"
              );
              this.message.description = this.$i18n.tc(
                "messages.password_erreur.description",
                error.response.data.tentatives
              );
            }
          }
        }
      } else {
        this.message.type = "info";
        this.message.couleur = "blue";
        this.message.titre = this.$i18n.t("messages.champ_vide.titre");
        this.message.description = this.$i18n.t(
          "messages.champ_vide.description"
        );
        if (
          this.login != "" &&
          this.password != "" &&
          this.password.length < 8
        ) {
          this.message.titre = this.$i18n.t("messages.password_taille.titre");
          this.message.description = this.$i18n.t(
            "messages.password_taille.description"
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.icon-username {
  @apply h-12 w-12;
  background-image: url("../assets/img/login.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.icon-password {
  @apply h-12 w-12;
  background-image: url("../assets/img/password.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.icon-email {
  @apply h-12 w-12;
  background-image: url("../assets/img/email.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

input#keops-login,
input#keops-password,
input#keops-email {
  @apply bg-coolGray-100 h-12;
}
input#keops-login::placeholder,
input#keops-password::placeholder,
input#keops-email::placeholder {
  @apply text-coolGray-800;
}

form input:focus {
  transition: all 0.2s ease-in-out 0s;
  box-shadow: inset 0 0 0 1px #63b3ed;
}

form button {
  outline: none;
  box-shadow: none;
}
</style>
